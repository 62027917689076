<template>
  <div>
    <header-list title="Settings" :btnOptions="headerButtons" />
    <main-container>
      <v-tabs class="ma-2" slider-color="primary" v-model="tabIndex">
        <v-tab key="general_tab" ripple> GENERAL </v-tab>
        <v-tab key="invoices_tab" ripple> ESTIMATES/INVOICES </v-tab>
        <!-- Removed integrations tab (TP2-327); TODO: re-enable when hubspot integration is complete -->
        <v-tab key="integrations_tab" ripple v-show="showIntegrationsTab">
          INTEGRATIONS
        </v-tab>
        <v-spacer></v-spacer>

        <v-tab-item key="general_tab">
          <v-card>
            <v-container fill-height>
              <v-layout wrap row align-center ma-2>
                <v-flex xs12 headline text-center>General Settings</v-flex>
                <v-flex xs12 class="section-label">
                  <h3>Locale Settings</h3>
                </v-flex>
                <v-flex xs2 pl-4> Country: </v-flex>
                <v-flex xs4>
                  <v-select
                    :disabled="!editMode"
                    v-model="tenant.country"
                    :items="countryItems"
                    autowidth></v-select>
                </v-flex>
                <v-flex xs6> </v-flex>
                <v-flex xs2 pl-4> Currency Symbol: </v-flex>
                <v-flex xs4>
                  <v-select
                    :disabled="!editMode"
                    v-model="tenant.currency_symbol"
                    :items="currencySymbols"
                    autowidth></v-select>
                </v-flex>
                <v-flex xs6> </v-flex>
                <v-flex xs2 pl-4> Unit Type: </v-flex>
                <v-flex xs4>
                  <v-select
                    :disabled="!editMode"
                    v-model="tenant.units_of_measurement"
                    :items="unitTypes"
                    autowidth></v-select>
                </v-flex>
                <v-flex xs6> </v-flex>

                <v-flex xs12 class="section-label">
                  <h3>Date &amp; Time Settings</h3>
                </v-flex>
                <v-flex xs2 pl-4> Date Format: </v-flex>
                <v-flex xs4>
                  <v-select
                    :disabled="!editMode"
                    v-model="tenant.date_format"
                    :items="dateFormats"
                    autowidth></v-select>
                </v-flex>
                <v-flex xs6> </v-flex>
                <v-flex xs2 pl-4> Time Format: </v-flex>
                <v-flex xs4>
                  <v-select
                    :disabled="!editMode"
                    v-model="tenant.time_format"
                    :items="timeFormats"
                    autowidth></v-select>
                </v-flex>

                <v-flex xs12 class="section-label">
                  <h3>Logo Settings</h3>
                </v-flex>
                <v-flex xs2 pl-4> Logo: </v-flex>
                <v-flex xs4>
                  <v-img
                    v-if="tenant.logo && tenant.logo != ''"
                    v-bind:src="tenant.logo"
                    aspect-ratio="1"
                    max-width="500"
                    max-height="300"></v-img>
                  <div v-else>
                    <span class="title">No Logo Image Available</span>
                  </div>
                  <v-file-input
                    class="mt-3"
                    v-if="editMode"
                    :rules="rules"
                    accept="image/png, image/jpeg, image/bmp"
                    placeholder="Upload Image"
                    prepend-icon="mdi-camera"
                    label="Upload Logo"
                    :loading="fileLoading"
                    v-model="file"
                    solo
                    @change="uploadLogo" />
                </v-flex>
              </v-layout>
            </v-container>
          </v-card>
        </v-tab-item>
        <v-tab-item key="invoices_tab">
          <v-card>
            <v-container fill-height>
              <v-layout wrap row align-center ma-2>
                <v-flex xs12 headline text-center>
                  Estimate &amp; Invoice Settings
                </v-flex>
                <v-flex xs12 class="section-label">
                  <h3>Sales Tax</h3>
                </v-flex>
                <v-flex xs2 pl-4> Sales Tax: </v-flex>
                <v-flex xs1>
                  <v-text-field
                    :disabled="!editMode"
                    v-model="tenant.tax_rate"
                    type="number"
                    suffix="%"
                    placeholder="0.0"></v-text-field>
                </v-flex>
                <v-flex xs9> </v-flex>
                <v-flex xs2 pl-4> Tax Type: </v-flex>
                <v-flex xs1>
                  <v-text-field
                    :disabled="!editMode"
                    v-model="tenant.tax_type"
                    placeholder=""></v-text-field>
                </v-flex>
                <v-flex xs9> </v-flex>
                <v-flex xs12 class="section-label">
                  <h3>Invoice Payment Address</h3>
                </v-flex>
                <v-flex xs2 pl-4> Company Name: </v-flex>
                <v-flex xs4>
                  <v-text-field
                    :disabled="!editMode"
                    v-model="tenant.invoice_name"></v-text-field>
                </v-flex>
                <v-flex xs6> </v-flex>
                <v-flex xs2 pl-4> Address: </v-flex>
                <v-flex xs4>
                  <v-text-field
                    :disabled="!editMode"
                    v-model="tenant.invoice_address"></v-text-field>
                </v-flex>
                <v-flex xs6> </v-flex>
                <v-flex xs2 pl-4> City: </v-flex>
                <v-flex xs4>
                  <v-text-field
                    :disabled="!editMode"
                    v-model="tenant.invoice_city"></v-text-field>
                </v-flex>
                <v-flex xs6> </v-flex>
                <v-flex xs2 pl-4> State: </v-flex>
                <v-flex xs4>
                  <v-text-field
                    :disabled="!editMode"
                    v-model="tenant.invoice_state"></v-text-field>
                </v-flex>
                <v-flex xs6> </v-flex>
                <v-flex xs2 pl-4> Postal Code: </v-flex>
                <v-flex xs4>
                  <v-text-field
                    :disabled="!editMode"
                    v-model="tenant.invoice_postal_code"></v-text-field>
                </v-flex>
                <v-flex xs6> </v-flex>
                <v-flex xs2 pl-4> Country: </v-flex>
                <v-flex xs4>
                  <v-text-field
                    :disabled="!editMode"
                    v-model="tenant.country"></v-text-field>
                </v-flex>
                <v-flex xs6> </v-flex>
                <v-flex xs2 pl-4> Phone: </v-flex>
                <v-flex xs4>
                  <v-text-field
                    :disabled="!editMode"
                    v-model="tenant.contact_phone"></v-text-field>
                </v-flex>
                <v-flex xs6> </v-flex>
                <v-flex xs12 class="section-label">
                  <h3>Estimate and Invoice Terms of Service</h3>
                </v-flex>
                <v-flex xs12 px-4>
                  <v-text-field
                    :disabled="!editMode"
                    v-model="tenant.invoice_terms"></v-text-field>
                </v-flex>
                <v-flex xs12 class="section-label">
                  <h3>Default Invoice Notes</h3>
                </v-flex>
                <v-flex xs12 px-4>
                  <v-text-field
                    :disabled="!editMode"
                    v-model="tenant.invoice_notes"></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card>
        </v-tab-item>
        <v-tab-item key="integrations_tab" v-show="showIntegrationsTab">
          <v-card>
            <v-container text-center>
              <v-row>
                <v-col cols="12" class="headline"> QUICKBOOKS </v-col>
              </v-row>

              <!-- TODO: enable with quickbooks rollout -->
              <v-row>
                <v-col cols="6">
                  <v-row>
                    <v-col>
                      Status:
                      {{
                        quickbooksStatus.enabled ? 'Connected' : 'Not Connected'
                      }}
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6">
                  <v-btn
                    class="button-primary px-2"
                    @click="qbAuthenticate"
                    v-if="!quickbooksStatus.enabled">
                    Connect to Quickbooks
                  </v-btn>

                  <v-btn
                    class="button-primary px-2"
                    @click="qbRefresh"
                    v-if="quickbooksStatus.enabled">
                    Refresh Connection
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6"> </v-col>
                <v-col cols="6" v-if="quickbooksStatus.enabled">
                  <v-btn class="error px-2" @click="qbRevoke">
                    Revoke Connection
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </main-container>
  </div>
</template>

<script>
  import Forms from '@/mixins/forms';
  import Localization from '@/mixins/localization';
  import HeaderList from '@/components/header-list';
  import MainContainer from '@/components/main-container';
  import MainCard from '@/components/main-card';
  import configurations from '@/mixins/configurations';
  import Tenants from '@/services/Tenants.service.js';

  export default {
    name: 'AppSettingsView',
    components: {
      'header-list': HeaderList,
      'main-container': MainContainer,
      // eslint-disable-next-line vue/no-unused-components
      'main-card': MainCard,
    },
    mixins: [Forms, configurations, Localization],
    props: {},
    data() {
      return {
        tabIndex: 0, // Current tab
        quickbooksStatus: {
          enabled: false,
        },
        editMode: false,
        fileLoading: false,
        disablers: [
          {
            text: 'Enabled',
            value: true,
          },
          {
            text: 'Disabled',
            value: false,
          },
        ],
        rules: [
          (value) => {
            if (value && value.size) {
              return (
                value.size < 4000000 || 'Logo size should be less than 4 MB!'
              );
            } else {
              return true;
            }
          },
        ],
        file: [],
        tenant: {},
        logo: '',
        showIntegrationsTab: false,
      };
    },
    computed: {
      headerButtons: function () {
        if (this.editMode) {
          return [
            {
              name: 'Save',
              icon: 'mdi-content-save',
              action: this.saveSettings,
            },
          ];
        } else {
          return [
            {
              name: 'Edit',
              icon: 'mdi-pencil',
              action: () => {
                this.editMode = true;
              },
            },
          ];
        }
      },
    },
    watch: {
      configuration: async function () {
        if (this.configuration && this.configuration.quickbooks) {
          // show the integrations tab in settings
          this.showIntegrationsTab = true;

          // Get the access token from the auth wrapper
          const accessToken = await this.$auth.getTokenSilently();
          // get quickbooks status
          const qbStatus = await Tenants.getQuickbooksTokenStatus(
            this.$auth.userProfile.tenant_uuid,
            accessToken
          );
          if (qbStatus) {
            this.quickbooksStatus = qbStatus;
          }
        }
      },
    },
    async created() {
      // get tenant profile
      this.tenant = await this.$auth.tenantProfile;
    },
    methods: {
      async qbAuthenticate() {
        const accessToken = await this.$auth.getTokenSilently();

        // get quickbooks authentication
        const res = await Tenants.getQuickbooksAuthentication(
          this.tenant.uuid,
          accessToken
        );
        if (res) {
          window.location.href = res;
        } else {
          console.log('error authenticating quickbooks');
        }
      },
      async qbRefresh() {
        const accessToken = await this.$auth.getTokenSilently();
        // get quickbooks authentication
        const res = await Tenants.refreshQuickbooksToken(
          this.tenant.uuid,
          accessToken
        );
        if (res) {
          console.log('refreshed qb token');
        } else {
          console.log('error refreshing qb token');
        }
      },
      async qbRevoke() {
        const accessToken = await this.$auth.getTokenSilently();
        // get quickbooks authentication
        const res = await Tenants.revokeQuickbooksAuthentication(
          this.tenant.uuid,
          accessToken
        );
        if (res) {
          console.log('revoked qb authentication');
          this.quickbooksStatus = {
            enabled: false,
          };
        } else {
          console.log('error revoking qb authentication');
        }
      },
      async saveSettings() {
        // console.log("Saving Tenant");

        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        // update tenant
        let res = await Tenants.updateTenant(
          this.tenant.uuid,
          this.tenant,
          accessToken
        );
        if (res) {
          // update tenant profile in auth (important!)
          this.$auth.reloadTenantProfile();

          // console.log("Tenant Saved");
          this.editMode = false;
        }
      },
      async uploadLogo() {
        if (this.file && this.file.size && this.file.size < 4000000) {
          this.fileLoading = true;
          let formData = new FormData();
          let tenantUuid = this.tenant.uuid;
          // Add the File object to the formdata
          formData.append('file', this.file);
          formData.append('tenantUuid', tenantUuid);
          //formData.append("filename");

          // Get the access token from the auth wrapper
          const accessToken = await this.$auth.getTokenSilently();

          // upload the file to S3
          let res_logo = await Tenants.uploadTenantLogo(formData, accessToken);

          this.file = undefined;
          this.fileLoading = false;

          // this updates it in the window display but will not
          // actually save it to the tenant until  the save button is clicked
          this.tenant.logo = res_logo.location;

          // todo: maybe delete the file if user decides to not save for cleanup?
        }
      },
    },
  };
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped>
  .section-label {
    color: #004370;
  }
</style>
